export const getErrorMsg = (error, withDefault = false) => {
    return error.response?.data?.detail || error.message || (withDefault ? 'An error occurred.' : null);
};

export const validatePhoneNumber = (phoneNumber, countryCode='US') => {
    const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

    // Only accept US phone numbers at this time in the input field.
    try {
        const number = phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode);
        return (
            phoneUtil.isValidNumberForRegion(number, countryCode) &&
            number.getCountryCode() === 1
        );
    } catch (e) {
        return false;
    }
};

export const capitalizeFirstLetter = (params) => {
    if (params.value) {
        return params.value.charAt(0).toUpperCase() + params.value.slice(1);
    }
    return params.value;
}